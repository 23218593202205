@font-face {
    font-family:'c';
    src:url( '../../public/fonts/Circe/Circe.ttf')
}
@font-face {
  font-family: 'g';
  src: url('../../public/fonts/Geometria/Geometria.ttf');
}
@font-face {
  font-family: 'm';
  src: url('../../public/fonts/MarkProMed/MarkProMed.ttf');
}